define('wnyc-studios-web-client/components/show-header', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Checks if the element's top edge is above the top of the viewport (used w/
  // the sentinel element to determine if the element below it should become
  // sticky)
  function isAboveViewport(element) {
    var rect = element.getBoundingClientRect();
    return rect.top >= 0;
  }

  // This function adds either a .hidden or .shown class to the show header
  // component, based on the height of the the sticky bar. For example, if the
  // full header is 400px high, and the sticky bar is 100px high, this calculates
  // that once the user scrolls 300px into the page (400 - 100), the sticky bar
  // should get displayed/stuck to the top.
  var triggerStickyClass = function triggerStickyClass() {
    var elStickyContainer = document.querySelector(".show-header--sticky-container");
    var sentinel = document.querySelector(".show-header--sentinel");

    if (!isAboveViewport(sentinel)) {
      elStickyContainer.classList.add("shown");
    } else {
      elStickyContainer.classList.remove("shown");
    }
  };

  exports.default = Ember.Component.extend({
    classNames: ["show-header"],
    classNameBindings: ["getTemplate"],
    mediaRoot: _environment.default.mediaRoot,
    getTemplate: Ember.computed("template", function () {
      return 'show-header--' + this.get("template") + '-template';
    }),
    isStory: Ember.computed.equal("template", "story"),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.sendAction('updateParent', {
        isHomepageForShow: Ember.get(this, 'isHomepageForShow')
      });
      window.addEventListener("scroll", triggerStickyClass, window.Modernizr.passiveeventlisteners ? { passive: true } : false);
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener("scroll", triggerStickyClass);
      this._super.apply(this, arguments);
    },

    navSlug: null, // initialized in route
    channelRoute: 'show-detail',
    activeTabIndex: null, // set by action
    isHomepageForShow: Ember.computed('activeTabIndex', 'navSlug', 'show', function () {
      var firstNavSlug = Ember.get(this, 'show.linkroll.0.nav-slug');
      var navSlug = Ember.get(this, 'navSlug');
      var index = Ember.get(this, 'activeTabIndex');
      if (index === 0) {
        return true;
      } else if ((index === null || index === undefined) && navSlug === firstNavSlug) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      updateNav: function updateNav(index) {
        Ember.set(this, 'activeTabIndex', index);
        this.sendAction('updateParent', {
          isHomepageForShow: Ember.get(this, 'isHomepageForShow'),
          navSlug: Ember.get(this, 'show.linkroll.' + index + '.nav-slug'),
          activeTabIndex: Ember.get(this, 'activeTabIndex')
        });
      }
    }
  });
});