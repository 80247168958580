define('wnyc-studios-web-client/routes/person-detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    metadata: Ember.inject.service(),
    titleToken: function titleToken(model) {
      return model.get('name');
    },
    pageType: 'Person',
    model: function model(params) {
      return this.get('store').findRecord('person', params.slug);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      var person = model;
      controller.set('person', person);
      controller.set('studiosSocialLinks', [{
        title: "facebook",
        href: "https://www.facebook.com/WNYCStudios/"
      }, {
        title: "twitter",
        href: "https://twitter.com/WNYCStudios"
      }]);
    },
    afterModel: function afterModel(person) {
      this.get('dataLayer').push('Page Type', this.get('pageType'));
      this.get('metadata').setHeadData({
        path: '/people/' + Ember.get(person, 'slug'),
        twitterCard: 'summary',
        description: Ember.get(person, 'lede'),
        image: Ember.get(person, 'image')
      });
    },

    actions: {
      willTransition: function willTransition() {
        this.get('dataLayer').clear('Page Type');
        return true;
      }
    }
  });
});