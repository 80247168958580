define('wnyc-studios-web-client/routes/shows', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    metadata: Ember.inject.service(),
    titleToken: 'All Shows',
    pageType: 'All Shows',
    model: function model() {
      return Ember.RSVP.hash({
        shows: this.store.query('show', { "site_id": _environment.default.siteId }),
        featuredShows: this.store.findRecord("bucket", 'wnyc-studios-featured-shows')
      });
    },
    afterModel: function afterModel() {
      this.get('dataLayer').push('Page Type', this.get('pageType'));
      this.get('metadata').setHeadData({
        path: '/shows'
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('shows', model.shows);
      controller.set('featuredShows', model.featuredShows);
      controller.set('studiosSocialLinks', [{
        title: "facebook",
        href: "https://www.facebook.com/WNYCStudios/"
      }, {
        title: "twitter",
        href: "https://twitter.com/WNYCStudios"
      }]);
    },

    actions: {
      willTransition: function willTransition() {
        this.get('dataLayer').clear('Page Type');
        return true;
      }
    }
  });
});