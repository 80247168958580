define('wnyc-studios-web-client/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DETAIL_ROUTES = new RegExp(/story|(show|series)-detail\./);

  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    dataPipeline: Ember.inject.service(),
    session: Ember.inject.service(),
    dj: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    title: function title() {
      var tokens = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      var siteName = 'WNYC Studios';
      var tagline = 'Podcasts';

      // combine the first two items if the second item starts with `:`
      if (tokens[1] && tokens[1].startsWith(':')) {
        tokens.splice(0, 2, '' + tokens[0] + tokens[1]);
      }

      tokens.push(siteName);
      if (tokens.length < 3) {
        tokens.push(tagline);
      }
      var title = tokens.join(' | ');
      this.get('dataLayer').setPageTitle(title);
      return title;
    },
    beforeModel: function beforeModel() {
      var _this = this;

      if (Ember.get(this, 'isFastBoot')) {
        return;
      }
      this.get('session').syncBrowserId().then(function (id) {
        return _this.get('dj').addBrowserId(id);
      });
      this.get('session').staffAuth();
    },


    actions: {
      error: function error(_error /*, transition*/) {
        if (_error && _error.response) {
          if (_error.response.status === 404) {
            this.transitionTo('missing');
          }
        } else {
          /* eslint-disable */
          console.error(_error);
          /* eslint-enable */
        }
      },
      didTransition: function didTransition() {
        var _this2 = this;

        if (Ember.get(this, 'isFastBoot')) {
          return;
        }
        this.set('dataPipeline.currentReferrer', window.location.toString());

        Ember.run.schedule('afterRender', function () {
          _this2.get('dataLayer').sendPageView();

          var route = _this2.router.currentRouteName;

          if (!DETAIL_ROUTES.test(route) && !route.match(/loading/)) {
            _this2.get('dataPipeline').reportItemView();
          }
        });
      },
      openSideNav: function openSideNav() {
        this.controllerFor('application').set('sideNavIsOpen', true);
      }
    }
  });
});