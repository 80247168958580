define('wnyc-studios-web-client/controllers/freestyle', ['exports', 'ember-freestyle/controllers/freestyle'], function (exports, _freestyle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var story = {
    "type": "story",
    "id": "815359",
    "audio": "https://www.podtrac.com/pts/redirect.mp3/audio.wnyc.org/fleas/fleas120517_episode3.mp3",
    "audioAvailable": true,
    "body": "<p><span>Waffles can’t help splashing around in the toilet bowl. But this time her bad behavior leads to the dreaded cone--just in time for a visit from Shep, the champion agility dog from next door. Jones hates company, but his spirits are lifted when a mouse crosses his path. It’s noogie woogie time!</span></p>\n<p> </p>\n<p><a href=\"http://www.wnyc.org/We%20want%20to%20hear%20from%20you!%20Click%20here%20to%20take%20our%20survey.\">We want to hear from you! Click here to send us feedback! </a></p>",
    "title": "Cone of Shame",
    "url": "http://www.wnycstudios.org/story/cone-shame-fleas-kids-podcast/",
    "newsdate": "2017-12-05T00:00:00-05:00",
    "slug": "cone-of-shame",
    "tease": "Waffles can&rsquo;t help splashing around in the toilet bowl. But this time her bad behavior leads to the dreaded cone--just in time for a visit from Shep.",
    "headers": {
      "brand": {
        "url": "http://www.wnycstudios.org/shows/fleas",
        "title": "This Podcast Has Fleas",
        "slug": "fleas",
        "logoImage": {
          "creditsUrl": "",
          "name": "1/TPHF_PodcastSquare_NoWNYCBranding_xrxxKYo.png",
          "source": null,
          "url": "https://media.wnyc.org/i/1400/1400/l/80/1/TPHF_PodcastSquare_NoWNYCBranding_xrxxKYo.png",
          "h": 1400,
          "isDisplay": true,
          "crop": "l",
          "caption": "",
          "template": "https://media.wnyc.org/i/%s/%s/%s/%s/1/TPHF_PodcastSquare_NoWNYCBranding_xrxxKYo.png",
          "w": 1400,
          "id": 170569,
          "creditsName": "WNYC Studios"
        }
      }
    },
    "imageMain": {
      "creditsUrl": "",
      "name": "1/WNYC_TPHF_episde-3-art.png",
      "source": null,
      "url": "https://media.wnyc.org/i/1600/1200/l/80/1/WNYC_TPHF_episde-3-art.png",
      "h": 1200,
      "isDisplay": true,
      "crop": "l",
      "caption": "",
      "template": "https://media.wnyc.org/i/%s/%s/%s/%s/1/WNYC_TPHF_episde-3-art.png",
      "w": 1600,
      "id": 170913,
      "creditsName": "Hanae Ko for WNYC Studios"
    },
    "podcastLinks": [{
      "href": "https://itunes.apple.com/us/podcast/id1318397377",
      "title": "via Apple Podcasts"
    }, {
      "href": "https://play.google.com/music/listen?u=0#/ps/I5mjpqk3sv5giom2j5bbjpisg3e",
      "title": "via Google Play Music"
    }, {
      "href": "https://www.stitcher.com/podcast/wnyc/this-podcast-has-fleas",
      "title": "via Stitcher"
    }, {
      "href": "http://feeds.wnyc.org/thispodcasthasfleas",
      "title": "via RSS"
    }]
  };

  var show = {
    type: "show",
    title: 'Note to Self',
    tease: 'An example tease for Note to Self.',
    hostTease: 'with Manoush Zomorodi',
    logoImage: {
      name: "1/NoteToSelf_1400X1400_TIt7xNg.png"
    },
    marqueeImage: {
      name: "1/NoteToSelf_MZ_header_3.jpg"
    }
  };

  var colors = {
    'white': {
      'name': '$white',
      'base': '#fffff'
    },
    'black': {
      'name': '$black',
      'base': '#000000'
    },
    'cyan': {
      'name': '$cyan',
      'base': '#4db7be'
    },
    'lime': {
      'name': '$lime',
      'base': '#dcf42c'
    },
    'light grey': {
      'name': '$light-grey',
      'base': '#dddddd'
    },
    'dark grey': {
      'name': '$dark-grey',
      'base': 'rgba(0, 0, 0, 0.6)'
    }
  };

  var hoverColors = {
    'white': {
      'name': '$white--hover',
      'base': 'rgba(255, 255, 255, 0.6)'
    },
    'black': {
      'name': '$black--hover',
      'base': 'rgba(0, 0, 0, 0.6)'
    },
    'cyan': {
      'name': '$cyan--hover',
      'base': 'rgba(17, 183, 190, 0.6)'
    },
    'lime': {
      'name': '$lime--hover',
      'base': 'rgba(220, 244, 44, 0.6)'
    },
    'light grey': {
      'name': '$lightgrey--hover',
      'base': 'rgba(221, 221, 221, 0.6)'
    },
    'dark grey': {
      'name': '$darkgrey--hover',
      'base': 'rgba(0, 0, 0, 0.3)'
    }
  };

  var linkroll = [{ "nav-slug": "listen", "title": "Listen" }, { "nav-slug": "the-team", "title": "The Team" }, { "nav-slug": "about", "title": "About" }, { "nav-slug": "very-long", "title": "A Very Very Very Very Very Long Title" }, { "nav-slug": "one-more", "title": "One More" }, { "nav-slug": "another", "title": "Another" }, { "nav-slug": "and-another", "title": "And Another" }, { "nav-slug": "yet-another", "title": "Yet Another" }, { "nav-slug": "still-another", "title": "Still Another" }, { "nav-slug": "penultimate", "title": "Penultimate" }, { "nav-slug": "final", "title": "The Final" }];

  var storyListApiResponse = {
    teaseList: [story, story, story]
  };

  exports.default = _freestyle.default.extend({
    emberFreestyle: Ember.inject.service(),

    colors: colors,
    hoverColors: hoverColors,
    linkroll: linkroll,
    navRoot: 'freestyle',
    storyListApiResponse: storyListApiResponse,
    story: story,
    show: show
  });
});