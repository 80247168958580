
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('wnyc-studios-web-client/app')['default'].create({"name":"wnyc-studios-web-client","version":"v2.0.8+c7fbd3bc"});
  }
}

define('~fastboot/app-factory', ['wnyc-studios-web-client/app', 'wnyc-studios-web-client/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

