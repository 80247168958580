define('wnyc-studios-web-client/routes/flat-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    metadata: Ember.inject.service(),
    titleToken: function titleToken(model) {
      return Ember.get(model, 'title');
    },

    pageType: 'General Flatpage',
    model: function model(params) {
      var _this = this;

      var flatPagePath = '/' + params[""];
      return this.store.query('flat-page', {
        filter: {
          url: flatPagePath
        }
      }).then(function (flatPages) {
        return flatPages.get("firstObject");
      }).catch(function (err) {
        if (!err.isAdapterError) {
          throw err;
        } else {
          _this.transitionTo('missing');
        }
      });
    },
    afterModel: function afterModel(model, transition) {
      this.get('dataLayer').push('Page Type', this.get('pageType'));
      this.get('metadata').setHeadData({
        path: Ember.get(transition, 'intent.url')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      controller.set('flatPage', model);
    },

    actions: {
      willTransition: function willTransition() {
        this.get('dataLayer').clear('Page Type');
        return true;
      }
    }
  });
});