define("wnyc-studios-web-client/routes/show-detail", ["exports", "wnyc-studios-web-client/mixins/global-show-mixin"], function (exports, _globalShowMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_globalShowMixin.default, {
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    dataPipeline: Ember.inject.service(),
    metadata: Ember.inject.service(),

    titleToken: function titleToken(model) {
      return model.channel.get('title');
    },
    model: function model(_ref) {
      var slug = _ref.slug;

      return Ember.RSVP.hash({
        channel: this.store.findRecord("channel", "shows/" + slug, { adapterOptions: { limit: 9 } }),
        showsYouMightLike: this.store.findRecord("bucket", slug + "-shows-you-might-like")
      });
    },
    afterModel: function afterModel(_ref2, transition) {
      var channel = _ref2.channel,
          showsYouMightLike = _ref2.showsYouMightLike;

      Ember.get(this, 'dataLayer').setForType('show', channel);
      if (Ember.get(showsYouMightLike, 'bucketItems')) {
        Ember.get(this, 'dataLayer').push('Shows You Might Like', 'Yes');
      } else {
        Ember.get(this, 'dataLayer').push('Shows You Might Like', 'No');
      }
      this.get('metadata').setHeadData({
        path: Ember.get(transition, 'intent.url'),
        twitterCard: 'summary_large_image',
        description: Ember.get(channel, 'tease'),
        image: Ember.get(channel, 'fbImage') ? Ember.get(channel, 'fbImage') : Ember.get(channel, 'logoImage'),
        feeds: [{
          type: 'application/rss+xml',
          title: Ember.get(channel, 'title') + " Feed",
          href: Ember.get(channel, 'rssFeed')
        }]
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);
      var show = model.channel;
      controller.set("show", show);
      controller.set("showsYouMightLike", model.showsYouMightLike);
      this.store.findRecord('chunk', show.get('slug') + "-show-callout").then(function (chunk) {
        controller.set('model.showCalloutChunk', chunk);
      }).catch(function (err) {
        if (!err.isAdapterError) {
          throw err;
        }
      });
      var navSlug = transition.params[transition.targetName].page_params;
      if (show.get("linkroll") && !navSlug) {
        navSlug = show.get("linkroll.0.nav-slug");
      }
      if (transition.targetName.startsWith('show-detail.project')) {
        navSlug = "projects";
      }
      controller.set('navSlug', navSlug);
      controller.send('updateNav');
    },


    actions: {
      willTransition: function willTransition() {
        this._super.apply(this, arguments);
        Ember.get(this, 'dataLayer').clearForType('show');
        Ember.get(this, 'dataLayer').clear('Shows You Might Like');
        return true;
      },
      didTransition: function didTransition() {
        var _this = this;

        Ember.run.schedule('afterRender', function () {
          Ember.get(_this, 'dataPipeline').reportItemView({
            cms_id: Ember.get(_this.currentModel.channel, 'cmsPK'),
            item_type: 'show'
          });
        });
        return true;
      }
    }
  });
});