define("wnyc-studios-web-client/components/support-subscribe-buttons", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["support-subscribe-buttons"],
    actions: {
      toggleOverlay: function toggleOverlay() {
        this.toggleProperty("showSubscribeOverlay");
      }
    }
  });
});