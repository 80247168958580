define('wnyc-studios-web-client/mixins/listing-route', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Mixin.create({
    pageNumbers: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    beforeModel: function beforeModel() {
      var channelType = this.routeName.split('.')[0];
      var channelPathName = (0, _emberInflector.pluralize)(channelType.split('-')[0]);
      Ember.setProperties(this, { channelType: channelType, channelPathName: channelPathName });
    },


    actions: {
      loading: function loading(transition) {
        if (Ember.get(this, 'isFastBoot')) {
          return false;
        }
        Ember.$('main > section:last-of-type').css('opacity', 0.5);
        transition.promise.finally(function () {
          Ember.$('main > section:last-of-type').css('opacity', 1);
        });
      },
      pageNumberClicked: function pageNumberClicked(page) {
        var channelType = Ember.get(this, 'channelType');
        var navSlug = this._getNavSlug(channelType);
        var projectParams = this.paramsFor(channelType + '.project');
        if (projectParams['project_slug']) {
          var channel = this.paramsFor(channelType)['slug'];
          var project = projectParams['project_slug'];
          this._scrollToOffset();
          this.transitionTo(channelType + '.project.page', channel, project, page);
        } else {
          this._scrollToOffset();
          this.transitionTo(channelType + '.page', navSlug ? navSlug + '/' + page : page);
        }
      }
    },

    _getNavSlug: function _getNavSlug(channelType) {
      var channel = Ember.get(this.modelFor(channelType), 'channel');

      var _paramsFor = this.paramsFor(channelType + '.page'),
          page_params = _paramsFor.page_params;

      var _ref = page_params ? page_params.split('/') : [],
          _ref2 = _slicedToArray(_ref, 1),
          navSlug = _ref2[0];

      var linkRollSlug = Ember.get(channel, 'linkroll.firstObject.nav-slug');
      var hasLinkRoll = Ember.get(channel, 'hasLinkroll');

      if (hasLinkRoll && navSlug && !/^\d+$/.test(navSlug)) {
        return navSlug;
      } else if (hasLinkRoll && linkRollSlug) {
        return linkRollSlug;
      } else {
        return false;
      }
    },
    _scrollToOffset: function _scrollToOffset() {
      window.scrollTo(0, 0);
    }
  });
});