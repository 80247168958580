define('wnyc-studios-web-client/services/metadata', ['exports', 'wnyc-studios-web-client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    headData: Ember.inject.service(),
    setHeadData: function setHeadData() {
      var metadata = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultMetadata = {
        type: 'website',
        twitterCard: 'summary',
        path: '',
        keywords: 'podcasts, npr, new york, WNYC Studios, arts, culture, classical, music, news, public, radio',
        description: 'WNYC Studios is leading the new golden age in audio with high quality storytelling that informs, inspires and delights millions of curious and highly engaged listeners. WNYC Studios produces award-winning podcasts including Radiolab, Here’s the Thing with Alec Baldwin, The New Yorker Radio Hour, Science Friday, and many others.',
        image: {
          url: _environment.default.mediaRoot + '/i/raw/1/WNYCStudiosHomePageOG_Cropped.jpg',
          w: '1200',
          h: '630'
        },
        feeds: []
      };

      // filter out keys with undefined values, so they get replaced
      // by defaults when we merge.
      var filteredData = Object.keys(metadata).filter(function (key) {
        return typeof metadata[key] !== 'undefined';
      }).reduce(function (filteredObject, key) {
        filteredObject[key] = metadata[key];
        return filteredObject;
      }, {});
      var mergedData = Object.assign({}, defaultMetadata, filteredData);
      mergedData.description = mergedData.description.replace(/(<([^>]+)>)/g, "");
      Ember.set(this, 'headData.type', mergedData.type);
      Ember.set(this, 'headData.twitterCard', mergedData.twitterCard);
      Ember.set(this, 'headData.url', 'https:' + _environment.default.webRoot + mergedData.path);
      Ember.set(this, 'headData.keywords', mergedData.keywords);
      Ember.set(this, 'headData.description', mergedData.description);
      Ember.set(this, 'headData.image', mergedData.image);
      Ember.set(this, 'headData.feeds', mergedData.feeds);
      Ember.set(this, 'headData.mediaRoot', _environment.default.mediaRoot);
      Ember.set(this, 'headData.webRoot', _environment.default.webRoot);
    }
  });
});