define("wnyc-studios-web-client/routes/story", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    dataPipeline: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    metadata: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    titleToken: function titleToken(model) {
      if (model.story.get('showTitle')) {
        return [model.story.get('title'), model.story.get('showTitle')];
      }
      return model.story.get('title');
    },
    model: function model(_ref, _ref2) {
      var _this = this;

      var slug = _ref.slug;
      var queryParams = _ref2.queryParams;

      return this.store.findRecord("story", slug, { adapterOptions: { queryParams: queryParams } }).then(function (story) {
        var showSlug = Ember.get(story, "show");
        return Ember.RSVP.hash({
          story: Ember.RSVP.resolve(story),
          show: _this.store.findRecord('show', showSlug),
          channel: _this.store.findRecord("channel", "shows/" + showSlug, { adapterOptions: { limit: 9 } })
        });
      });
    },
    afterModel: function afterModel(_ref3) {
      var story = _ref3.story,
          show = _ref3.show;

      if (!Ember.get(this, 'isFastBoot')) {
        Ember.get(this, 'dataLayer').setForType('story', story);
      }
      this.get('metadata').setHeadData({
        type: 'article',
        path: "/story/" + Ember.get(story, 'slug'),
        twitterCard: 'summary_large_image',
        description: Ember.get(story, 'tease'),
        image: Ember.get(story, 'imageMain'),
        feeds: [{
          type: 'application/rss+xml',
          title: Ember.get(show, 'title') + " Feed",
          href: Ember.get(show, 'rssFeed')
        }]
      });
    },

    actions: {
      willTransition: function willTransition() {
        Ember.get(this, 'dataLayer').clearForType('story');
        return true;
      },
      didTransition: function didTransition() {
        var _this2 = this;

        var story = this.currentModel.story;

        Ember.run.schedule('afterRender', function () {
          Ember.get(_this2, 'dataPipeline').reportItemView({
            cms_id: Ember.get(story, 'cmsPK'),
            item_type: Ember.get(story, 'itemType')
          });
        });
        return true;
      }
    }
  });
});